import React from 'react';

import './PhoneMock.css';

const PhoneMock = ({title, text, src}) => {


    return (
        <div id='phone-mock'>
            <div className='phone-mock-img-wrapper'>
                <img className='phone-mock-img' src={src} alt={title} />
            </div>
            <div className='phone-mock-text-wrapper'>
                <h1 className='phone-mock-text-title'>{title}</h1>
                <p className='phone-mock-text-content'>{text}</p>
            </div>
        </div>
    )
};

export default PhoneMock;