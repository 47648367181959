import React, {useState} from 'react';
import Carousel from 'react-spring-3d-carousel';
import './Highlights.css';
import { config } from "react-spring";
import FeatureCard from '../../shared/components/FeatureCard';



const Highlights = () => {

    const [slideKey, setSlideKey] = useState(0);

    const slides = [
        {
          key: 0,
          content: <FeatureCard src='https://content.docked.co/img/stKits.jpg' title='Port Information' showButton={false} url='/ports' content={`
          We are now adding port information to all ports of call. 
          This will help you maximise your shore leave. 
          Over 2021/22 we plan to dramatically enhance port information for over 1200 ports of call around the globe.
          If you have any information about any of the ports that you want to share please contact us. 
          `} />,
          onClick: ()=>setSlideKey(1)
        },
        {
            key: 1,
            content: <FeatureCard src='https://content.docked.co/img/ship-checker.jpeg' title='Ship Checker' showButton={true} buttonTitle='Ship Checker' url='/ship-checker' content={`
            Now you can see what ships we are currently supporting. 
            The Ship Checker will also let you know if we have your ports of call for your contact. 
            If we do not currently support your ship or fleet, let us know. 
            `} />,
            onClick: ()=>setSlideKey(2)
        },
        {
            key: 2,
            content: <FeatureCard src='https://content.docked.co/img/horizonbow.jpeg' title='On the Horizon' showButton={false} content={`
            2021 is a big year for Docked. 
            We are undergoing a complete refit, with new branding and big plans. 
            Currently we still only has the iOS app avaliable but we have plans to launch andriod and a web app. 
            Later in the year we hope to have a lot more exicitng announcments as we get launch dates for our completey new App.
            `} />,
            onClick: ()=>setSlideKey(0)
        }
      ];




    return (
        <section id='highlights'>
            <div className='highlight-content'>
            <Carousel 
                slides={slides} 
                goToSlide={slideKey}
                offsetRadius={1}
                animationConfig={config.wobbly}

                />
            </div>
           
        </section>
    )
}

export default Highlights;