import React from 'react';

import './About.css'

const About = () => {

    return (
        <div id='about-page__wrapper'>
        <h1>About</h1>

        </div>
    );
}

export default About;