import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './Header.css';
import TopLinks from '../Nav/TopLinks';
import MobileNav from '../Nav/MobileNav';
const links = [
  {
    uri: '/home',
    title:"Home"
  },
  {
    uri: '/ship-checker',
    title:"Ship Checker"
  }
]

const Header = props => {

  return (
    <div className="page-header__wrapper">
      <img src='https://content.docked.co/img/logos/docked-long-white.svg' alt='Docked Logo'  height='25px'/>
      <div className="nav__wrapper">
        <TopLinks links={links} />
        <MobileNav links={links} />
      </div>
    </div>
  );
};

export default Header;
