import React from 'react';

import './FeatureCard.css';
import ButtonLink from './UIElements/Buttons/ButtonLink';

const FeatureCard = ({ src, content, title, showButton, buttonTitle, url}) => {

    return (
        <div id='feature-card'>
            <div className='feature-card-content'>
                <h1>{title}</h1>
                <p>{content}</p>
                {showButton && <ButtonLink title={buttonTitle}  href={url}/>}
            </div>
            <div className='feature-card-image'>
                <img src={src} alt={title} />
            </div>
        </div>
    )
}

export default FeatureCard;