import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';

import './Button.css';

const ButtonLink = props => {
  const [isDisabled, setIsDisabled] = useState();
  const [link, setLink] = useState('');

  useEffect(() => {
    setIsDisabled(props.disabled);
    setLink(props.href)
  }, [props.disabled, props.href]);

  return (
    <React.Fragment>
      {!isDisabled ? (
        <div id={`${props.id}`} className={`button ${props.className}`}>
        {link ?  <NavLink to={link}>{props.title}</NavLink> : <NavLink to='/'>{props.title}</NavLink>}
         
        </div>
      ) : (
        <div
          id={props.id}
          className={`button ${props.className} button-disabled`}
        >
          <span>{props.title}</span>
        </div>
      )}
    </React.Fragment>
  );
};

export default ButtonLink;
