import React from 'react';
import { Link } from 'react-router-dom';

import './FooterLinks.css';

const FooterLinks = () => {
return (


    <ul className="footer-links__ul">
        <li className="footer-links__li">
            <Link to='/ship-checker'>
                Ship Checker
            </Link>
        </li>
       
       
        <li className="footer-links__li">
            <Link to='/terms'>
                Terms & Conditions
            </Link>
        </li>
        <li className="footer-links__li">
            <Link to='/privacy'>
                Privacy Policy
            </Link>
        </li>
    </ul>
);

};

export default FooterLinks;