import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';


import './MobileNav.css';

const MobileNav = ({ links }) => {
  const [navOpen, setNavOpen] = useState(false);

  const toggle = () => {
    if (navOpen) {
      setNavOpen(false);
    } else {
      setNavOpen(true);
    }
  };

  return (
    <div className="main-nav__wrapper">
      <div
        className={`bar-container ${navOpen ? 'change' : null}`}
        onClick={toggle}
      >
        <div className={`bar1`}></div>
        <div className="bar2"></div>
        <div className="bar3"></div>
      </div>

      <div
        className={`nav-draw__wrapper ${!navOpen ? 'close' : null}`}
        onClick={toggle}
      >
      <ul  className="mobile-nav">
        {links && links.map(link => (
            <li key={link.title}>
            <NavLink className='mobile-link-a' to={link.uri}>{link.title}</NavLink>
            
            </li>
          
        ))}
      </ul>
       

       
      </div>
    </div>
  );
};

export default MobileNav;
