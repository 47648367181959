import React, {Suspense} from 'react';
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch,
} from 'react-router-dom';

import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons';
import { 
  faSearch,
  faEnvelope, 
  faCheckCircle,
  faTimesCircle,
} from '@fortawesome/free-solid-svg-icons';

import Header from './shared/components/Header/Header';
import Footer from './shared/components/Footer/MainFooter';
import Home from './home/pages/Home';
import Contact from './contact/pages/Contact';
import ShipChecker from './shipChecker/pages/ShipChecker';
import Support from './support/pages/Support';
import About from './about/pages/About';
import Features from './features/pages/Features';
import Terms from './legal/pages/Terms';
import Privacy from './legal/pages/Privacy';

import LoadingSpinner from './shared/components/UIElements/Spinners/LoadingSpinner';
import './App.css';

library.add(
  fab,
  faCheckCircle,
  faTimesCircle,
  faSearch,
  faEnvelope,
  );

function App() {

  let routes = (
    <React.Fragment>
        <Switch>
          <Route path="/" exact>
            <Home />
          </Route>
          <Route path="/ship-checker" exact>
            <ShipChecker />
          </Route>
          <Route path="/features">
          <Features />
        </Route>
        <Route path="/about">
          <About />
        </Route>
        <Route path="/contact">
          <Contact />
        </Route>
        <Route path="/support">
          <Support />
        </Route>
        <Route path="/terms">
          <Terms />
        </Route>
        <Route path="/privacy">
          <Privacy />
        </Route>
          <Redirect to="/" />
        </Switch>
    </React.Fragment>
  );

  return (
    <Router>
      <Header />
        <main>
          <Suspense 
            fallback={
              <div className="center">
                <LoadingSpinner />
              </div>
            }
          >
            {routes}          
          </Suspense>
        </main>
        <Footer />
      </Router>
  );
}

export default App;
