import React from 'react';

import './Branding.css';

const Branding = props => {

    return (
        <div className={`branding ${props.className}`} style={props.style}>
        <img
          src='https://content.docked.co/img/logos/docked-logo.svg'
          alt='Docked Logo'
          style={{ width: props.width, height: props.width }}
        />
      </div>
    );
};


export default Branding;