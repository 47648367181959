import React, {useState} from 'react';
import PhoneMock from '../../shared/components/UIElements/PhoneMock';


import './AppFeatures.css';


const AppFeatures = () => {

    const [slideKey, setSlideKey] = useState(0);

    const AppFeaturesContent = [
        {
          key: 0,
          title:'Today Screen',
          text: `Here you can see where you are on any given day, aswell as where all your friends are. \nYou can also see all the dates and locations of when you dock with friends.\n\nAt the bottom you can see where you are in your contract, such as how many ports you have left until the end of your contrat. `,
          src:'https://content.docked.co/img/mock-ups/x_home.png'
        },
        {
            key: 1,
            title:'Your Itinerary',
            text:`You can add your store your itinerary locally on your device so its always avaliable, even when at sea without an internet connection. \n\n You can see what ports and countries you will be calling at and (when avaliable) estimated times of arrival and departure.`,
            src:'https://content.docked.co/img/mock-ups/x_itinerary.png'
        },
        {
            key: 2,
            title: 'Save Friends',
            text: `You also have the abiliy to save your friends itineraraies on device\n\n The app will show if and when you dock together with any of your friends. Never miss your friends in port again.`,
            src:'https://content.docked.co/img/mock-ups/x_friends.png'
           
        }
      ];

    return (
        <section id='app-features'>
            <div className='app-features-content'>
            <h1 className='app-features-content-title'>App Features</h1>
            {AppFeaturesContent && AppFeaturesContent.map(item => (
                <PhoneMock key={item.key} src={item.src}  title={item.title} text={item.text}/>
            ))}
            <p className='app-features-content-summery'>These are just some of the great features Docked currently has with a whole lot more in the pipleline. This app is essential to help crew members keep track of they are going and keep track of friends at sea. </p>
            <p className='app-features-content-terms'>All these features depend on data avaliablity for the operators and ships, please use our ship checker to check if your contract is supported</p>
            </div>
           
        </section>
    )
}

export default AppFeatures;