import React from 'react';

import './Support.css'

const Support = () => {

    return (
        <div id='support__wrapper'>
            <h1>Support</h1>

        </div>
    );
}

export default Support;