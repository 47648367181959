import React from 'react';
import Branding from '../Branding';
import FooterLinks from './FooterLinks';
import './MainFooter.css';

const MainFooter = () => {
return (
    <footer>
    <div className="main-footer__shell">
    
    <div className='main-footer__brand'>
        <Branding style={{height:'140px'}} />
        <p className='main-footer_p'><a href="mailto:info@docked.co?subject=Mail from site footer">info@docked.co</a> </p>
    </div>
    <div className='main-footer__links'>
        <FooterLinks />
    </div>
       
        <div className="main-footer__bottom">
            <p> &copy; Copyright 2021 - Docked - A product owned and developed by <a className='helogale' href='https://helogale.com' target='blank'>Helogale</a></p>
        </div>

    </div>
    </footer>

)};

export default MainFooter;