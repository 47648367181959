import React from 'react';

import Button from '../../shared/components/UIElements/Buttons/ButtonLink';
import './Hero.css';

const Hero = () => {
    return (
        <section id='hero'>
         <div className='hero-blend'></div>
            <div className='hero-content'>
                <img className='hero-content-logo' src='https://content.docked.co/img/logos/logo_white.svg' alt='Docked Logo' />
                <h1 className='hero-content-strap'>Connecting Crew at Sea!</h1>
                <h2 className='hero-content-tag'>We're Back!</h2>
                <h3 className='hero-content-tag2'>with big plans on the horizon</h3>
                <p className='hero-content-p'>Use the ship checker to see if we support your ship.</p>
                <Button className='button-secondary hero-cta' href='/ship-checker' title='Ship Checker' />
            </div>
           
        </section>
    )
}

export default Hero;