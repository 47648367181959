import React, {useState, useEffect} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Loading from '../../shared/components/UIElements/Loading'
import Button from '../../shared/components/UIElements/Buttons/Button';
import { useHttpClient } from '../../shared/hooks/http-hook';
import './ShipChecker.css'

const ShipChecker = () => {

    const [companies, setCompanies] = useState([]);
    const [ships, setShips] = useState([]);
    const [currentCompanyId, setCurrentCompanyId] = useState();
    const [currentShipId, setCurrentShipId] = useState();
    const [isDisabled, setIsDisabled] = useState(false);
    const [isShipDisabled, setIsShipDisabled] = useState(false);
    const { isLoading, sendRequest, error, clearError } = useHttpClient();
    const [result, setResult] = useState();


    const chooseCompany = {
        id: '0',
        name:"Choose a Company"
    }

    useEffect(() => {
        const fetchCompanies = async () => {
            try {
            const responseData = await sendRequest(
                `https://api.docked.co/api/companies`
                );

                if (responseData) {
                    var companyData = responseData.companies;
                    companyData.unshift(chooseCompany);
                    setCompanies(companyData);
                    console.log(companyData);
                }
            } catch (err) {
            console.log(err);
            }
        };
        fetchCompanies();
      
  }, [sendRequest]);  

  useEffect(() => {
console.log(currentCompanyId);

    const fetchShip = async () => {
      try {
        const responseData = await sendRequest(
          `https://api.docked.co/api/ships/company`,
          'POST',
          JSON.stringify({companyId: currentCompanyId,}),
          {
            'Content-Type': 'application/json',
          }
          );
        setShips(responseData.ships);
        setCurrentShipId(responseData.ships[0].id)
        setIsDisabled(false);
      } catch (err) {
        console.log(err);
      }
    };


    if (currentCompanyId !== '0') {
        console.log('fetching');
        fetchShip();
    } else {
        setIsDisabled(false);
    }
    
    
}, [sendRequest, currentCompanyId, ]);  


    const selectedCompany = event => {
        if(event.target.value === '0') {
            setShips([]);
            setResult();
        } else {
            setCurrentCompanyId(event.target.value);
            setIsDisabled(true);
        }
    }

    const selectedShip = event => {
        setCurrentShipId(event.target.value); 
    }

    const checkShip = async () => {
       
       if (currentShipId) {
           console.log('checking');
           console.log(currentShipId);
           setIsDisabled(true);
           setIsShipDisabled(true);

          
            console.log("lastport");
              try {
                const responseData = await sendRequest(
                  `https://api.docked.co/api/port-day/ship-lastport`,
                  'POST',
                  JSON.stringify({
                  shipId: currentShipId
                  }),
                  {
                    'Content-Type': 'application/json'
                  }
  
                );

                setIsDisabled(false);
                setIsShipDisabled(false);
               
                if (responseData.lastPortDay) {
                    setResult(responseData.lastPortDay);
                    console.log(responseData.lastPortDay);
                } else {
                    setResult([])
                    console.log('no ports found');
                }
               
              } catch (err) {
                  console.log(err);
              }

           setIsShipDisabled(false);
       } else {
           console.log('no ship selected');
       }
    }

    return (
        <div id='ship-checker__wrapper'>
            <div className='ship-checker__content'>
                <h1 className='ship-checker__content-title'>Ship Checker</h1>
                <p className='ship-checker__content-text'>Use this tool to see if we currently support your ship</p>
                <div className='checker-form__wrapper'>
                    
                        <div className='checker-form-input-group'>
                         
                            <select 
                                id='companySelect'
                                className='cfi-company'
                                defaultValue='Loading companies...'
                                onChange={selectedCompany} 
                                disabled={isDisabled ? 'disabled' : ''}
                            >
                            {companies.length > 0 ? companies.map(company =>(
                                <option 
                                    key={company.id} 
                                    value={company.id}
                                     >
                                    {company.name}
                                    </option>
                                    ))
                                    :
                                    <option value='0' >Loading...</option>
                                    };
                            </select>
                            <select  
                                id='shipSelect' 
                                name='ships'
                                className='cfi-ship' 
                                defaultValue='choose a Ship'
                                onChange={selectedShip} 
                                disabled={isShipDisabled ? 'disabled' : ''}
                            >
                            {ships.length > 0 ? ships.map(ship =>(
                                <option 
                                    key={ship.id} 
                                    value={ship.id} >
                                    {ship.name}
                                    </option>
                                    ))
                                    :
                                    <option value='0' >Select a company to load fleet</option>
                                    };
                            </select>
                        </div>
                        {<Button title='Check' className='button-primary ship-check-btn' onClick={checkShip}/>}
                </div>

                {result && result.length > 0 && <div className='checker-results__wrapper'>
                    <div className='checker-results-title__wrapper'>
                        <FontAwesomeIcon icon={'check-circle'} size={'2x'}/>
                        <h1>Supported</h1>
                    </div>
                    <div className='checker-results-content__wrapper'>
                        <p>We have ports up till {result[0].portDate}</p>
                        <p>This ship will be updated periodicallly. If you notice any ports that are incorrect contact support and we will get it updated.</p>
                    </div>        
                </div>}

                {result && result.length === 0 && <div className='checker-results__wrapper'>
                    <div className='checker-results-title__wrapper fail-title__wrapper'>
                        <FontAwesomeIcon icon={'times-circle'} size={'2x'}/>
                        <h1>No Ports</h1>
                    </div>
                    <div className='checker-results-content__wrapper'>
                        <p>No Ports were found for this ship at present, however, the ship is listied in our database and ports will be added in the coming weeks.  </p>
                        <p>Check back soon or contact support to prioritse this ship.</p>
                    </div>        
                </div>}
                {isLoading && <div className='ship-checker-isloading'><p>Loading </p><Loading /></div>}
            </div>
            
        </div>
    );
}

export default ShipChecker;