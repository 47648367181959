import React from 'react';

import './Features.css'

const Features = () => {

    return (
        <div id='features__wrapper'>
            <h1>Features</h1>

        </div>
    );
}

export default Features;