import React from 'react';

import './Contact.css'

const Contact = () => {

    return (
        <div id='contact__wrapper'>
        <h1>Contact</h1>

        </div>
    );
}

export default Contact;