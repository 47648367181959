import React from 'react'; 
import { NavLink } from 'react-router-dom'; 

import './TopLinks.css'; 

const TopLinks = ( {links} ) => {

    return (
        <React.Fragment>
            <ul className="top-links">
                {links && links.map(link => (
                    <li key={link.title}>
                        <NavLink to={link.uri}>{link.title}</NavLink>
                    </li>
                ))}
            </ul>  
    </React.Fragment>
    );
};

export default TopLinks;