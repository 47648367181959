import React from 'react';
import AppFeatures from '../components/AppFeatures';
import Hero from '../components/Hero';
import Highlights from '../components/Highlights';

import './Home.css'

const Home = () => {

    return (
        <div id='homepage__wrapper'>
         <Hero />
         <Highlights />
         <AppFeatures />

        
       
        </div>
    );
}

export default Home;